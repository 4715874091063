<template>
  <div>
    <WebInput
      v-model="form.cardNumber"
      inputmode="numeric"
      :class-name="inputClass"
      :disabled="disabled"
      :tab-index="inputTabIndex"
      :placeholder="translate('generate.placeholder.cardnumber', locale)"
      name="Card Number"
      type="card"
      :label="getInputLabel(translate('generate.label.cardnumber', locale), isLabelVisible)"
      :label-style="labelStyle"
      :rules="{ required: isCreditCardAction, card: isCreditCardAction }"
      :page-options="pageOptions"
      emit-outside-on-enter
      @error="handleInputError"
      @card-cvv-mask-change="handleCvvMask"
    />
    <WebInput
      v-model="form.cardHolder"
      :class-name="inputClass"
      :disabled="disabled"
      :tab-index="inputTabIndex"
      :placeholder="translate('generate.placeholder.cardholder', locale)"
      name="Card Holder"
      type="text"
      :label="getInputLabel(translate('generate.label.cardholder', locale), isLabelVisible)"
      :label-style="labelStyle"
      :rules="{ required: isCreditCardAction, min: 3 }"
      :page-options="pageOptions"
      emit-outside-on-enter
      @error="handleInputError"
    />
    <div class="grid grid-cols-2 gap-x-16">
      <div
        v-if="isLabelVisible"
        :style="labelStyle"
        class="mb-8 flex items-end gap-8 text-14 font-medium text-neutral-900"
      >
        {{ getInputLabel(translate('generate.label.expiration', locale)) }}
      </div>
      <div
        v-if="isLabelVisible"
        :style="labelStyle"
        class="mb-8 flex items-end gap-8 text-14 font-medium text-neutral-900"
      >
        {{ getInputLabel(translate('generate.label.cvv', locale)) }}
      </div>
      <WebInput
        v-model="form.cardExpirationDate"
        :class-name="['!mb-0', { ...inputClass }]"
        :disabled="disabled"
        :tab-index="inputTabIndex"
        :placeholder="translate('generate.placeholder.expiration', locale)"
        mask="00/00"
        name="Expiration Date"
        type="mask"
        :rules="{ expirationDate: isCreditCardAction }"
        inputmode="numeric"
        :page-options="pageOptions"
        emit-outside-on-enter
        @error="handleInputError"
      />
      <WebInput
        ref="cvvInput"
        v-model="form.cardCVV"
        :class-name="['!mb-0', { ...inputClass }]"
        :disabled="disabled"
        :tab-index="inputTabIndex"
        :placeholder="translate('generate.placeholder.cvv', locale)"
        :mask="cvvMask"
        name="Card CVV"
        type="mask"
        :rules="{
          required: isCreditCardAction,
          min: isCreditCardAction ? cvvMin : null
        }"
        :page-options="pageOptions"
        inputmode="numeric"
        emit-outside-on-enter
        @error="handleInputError"
      >
        <template #right>
          <WebPopover
            trigger="mouseenter"
            placement="bottom-end"
            animation="shift-away"
            class="-ml-16 cursor-pointer"
          >
            <img
              :src="getAssetFromCdn('cards/info.svg')"
              alt="Info"
              class="size-16"
            />
            <template #content>
              <div class="flex justify-center gap-8">
                <img
                  :src="getAssetFromCdn('cards/cvv.svg')"
                  class="w-48"
                  alt="CVV"
                />
                <img
                  :src="getAssetFromCdn('cards/cvvAmex.svg')"
                  class="w-48"
                  alt="CVV Amex"
                />
              </div>
            </template>
          </WebPopover>
        </template>
      </WebInput>
    </div>
    <RegisterInput
      v-if="pageData.registerOnPayment"
      key="creditCardRegisterInput"
      v-model:subscriber-id="form.email"
      :disabled="pageData.bypassRegister"
      name="CreditCard_RegisterInput"
      hide-full-name-input
      class="mt-16"
      :locale="locale"
      :is-editor-mode="isEditorMode"
      :element="element"
      :page-data="pageData"
      :page-options="pageOptions"
      @error="handleInputError"
    />

    <AcceptPolicy
      v-if="isEditorMode || pageData.isPolicyRequired"
      v-model="form.acceptPolicy"
      :page-options="pageOptions"
      :page-data="pageData"
      :locale="locale"
      :consent-style="consentStyle"
      :input-class="inputClass"
      :is-editor-mode="isEditorMode"
      :disabled="disabled"
      class="mt-16"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, type PropType, nextTick, watch } from 'vue';
import { type PaymentForm, PaymentProvider } from '@shared/elements/common/payment/types';
import WebPopover from '@shared/components/popover/index.vue';
import WebInput from '@shared/components/input/index.vue';
import { globalEmit, getAssetFromCdn } from '@shared/utils/helpers';
import type { PageOptions, Element as ElementType } from '@shared/types/model';
import { useTranslate } from '@shared/composable/useTranslate';
import RegisterInput from '@shared/elements/common/registration/components/registerInput.vue';
import AcceptPolicy from '../accept-policy/index.vue';

const { translate } = useTranslate();
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Object as PropType<PaymentForm>,
    default: () => ({
      email: '',
      cardNumber: '',
      cardHolder: '',
      cardExpirationDate: '',
      cardCVV: '',
      acceptPolicy: false
    })
  },
  isEditorMode: { type: Boolean, default: true },
  labelStyle: { type: Object, default: () => ({}) },
  consentStyle: { type: Object, default: () => ({}) },
  isLabelVisible: { type: Boolean, default: true },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  pageData: { type: Object as PropType<Record<string, any>>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'payment' | 'payment-with-tabs'>>, default: () => ({}) }
});

const form = computed(() => props.modelValue);
const isCreditCardAction = computed(() => {
  return props.pageData.paymentMethod === PaymentProvider.CREDIT_CARD;
});

watch(
  () => form.value,
  (value) => {
    emit('update:modelValue', value);
  },
  { deep: true }
);

const cvvMask = ref('000');
const cvvMin = ref(3);
const cvvInput = ref();

const inputClass = computed(() => ({
  'pointer-events-none': props.isEditorMode
}));

function getInputLabel(label = '', show = true) {
  return show ? label : '';
}

const inputTabIndex = computed(() => (props.isEditorMode ? '-1' : undefined));

function handleInputError(errMessage: string) {
  globalEmit('paymentFormInputError', errMessage);
}

function handleCvvMask(mask: string) {
  cvvMin.value = mask.length;
  nextTick(() => {
    if (cvvInput.value) cvvInput.value.applyNewMask(mask);
  });
}
</script>
